import React, { useContext, useState } from "react";
import user from "../assets/icons/user-2.png";
import holiday from "../assets/icons/Frame 1000007502.svg";
import { AppContext } from "../context/appContext";
import {
  ApiConfiq,
  IMG_BASE_URL,
  REDIRECT_BASE_URL,
} from "../chatbot/api/apiConfig";
import like from "../assets/icons/like Button.svg";
import dislike from "../assets/icons/Unlike Button.svg";
import UnLikeActve from "../assets/icons/Unlike Button - active.svg";
import likeActive from "../assets/icons/like Button - active.svg";
import { PostCallWithErrorResponse } from "../chatbot/api/apiservice";
import QuestionList from "./questionlist";

const DisplayAnswers = (props) => {
  const {
    subModuleName = "",
    question = "",
    answer = "",
    icon = "",
    ilstr = "",
    ilstrDescription,
    endUrl = "",
    suggestionQustion,
    getNext
  } = props;

  const [isLike, setIsLike] = useState(false);
  const [isDisLike, setIsDisLike] = useState(false);

  const { colorSettings, userData } = useContext(AppContext);

  const handleLikeDislike = (isLike = true) => {
    PostCallWithErrorResponse(ApiConfiq.UPDATE_LIKE_DISLIKE, {
      question_likes_text: question,
      domain_id: 4,
      question_user: userData?.userSession,
      question_likes: isLike,
      question_review: "",
    }).then((res) => {
      if (res?.json?.success) {
        setIsLike(isLike);

        setIsDisLike(!isLike);
      }
    });
  };

  console.log(suggestionQustion);

  return (
    <div>
      <div className="row">
        <div className={"col-6 mb-4"}>
          <div
            className="card p-4 "
            style={{
              width: "100%",
              border: "none",
              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
              position: "relative",
            }}
          >
            <div
              className="card-header"
              style={{ backgroundColor: "white", borderBottom: "none" }}
            >
              <div className="d-flex align-items-center">
                <img
                  src={IMG_BASE_URL + icon}
                  onError={(e) => (e.target.style.display = "none")}
                  style={{
                    maxWidth: "30px",
                    maxHeight: "30px",
                    marginRight: "5px",
                  }}
                />
                <p
                  className="my-auto"
                  style={{
                    color: "rgba(18, 18, 18, 1)",
                    fontSize: "24px",
                    lineHeight: "18px",
                  }}
                >
                  {subModuleName}
                </p>
              </div>
              <div className="border mt-3"></div>
            </div>
            <div className="card-body">
              <p
                className=""
                style={{
                  color: "rgba(0, 0, 0, 0.5)",
                  fontSize: "24px",
                  lineHeight: "32px",
                  fontWeight: "600",
                }}
              >
                {question}
              </p>
              <div className="d-flex align-items-center">
                <img
                  src={colorSettings?.data?.bot_logo}
                  style={{
                    maxWidth: "30px",
                    maxHeight: "30px",
                    marginRight: "5px",
                  }}
                />
                <p
                  className="mb-1 my-auto"
                  style={{ fontSize: "24px", lineHeight: "18px" }}
                >
                  Answers
                </p>
              </div>
              <p
                className="mt-2"
                style={{ fontSize: "16px" }}
                dangerouslySetInnerHTML={{
                  __html:
                    answer?.type_name === "bot"
                      ? answer?.message
                      : answer?.data,
                }}
              ></p>
            </div>
            <div
              style={{ position: "absolute", bottom: "-27px", right: "30px" }}
            >
              <img
                src={isLike ? likeActive : like}
                className="me-2"
                onClick={() => handleLikeDislike(true)}
              />
              <img
                src={isDisLike ? UnLikeActve : dislike}
                className="me-2"
                onClick={() => handleLikeDislike(false)}
              />
            </div>
          </div>
          <div className="mt-4">
            {
              suggestionQustion?.length &&
              suggestionQustion.map((ques, index) => {

                return <QuestionList
                  ques={ques}
                  fromAns={true}
                  getNext={getNext}
                />
              })
            }
          </div>
        </div>
        <div className="col-6">
          <div
            className="card p-4 "
            style={{
              width: "95%",
              border: "none",
              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
            }}
          >
            <p className="mb-4">
              <a target="_blank">{REDIRECT_BASE_URL + endUrl}</a>
            </p>
            {/* <img
              src={IMG_BASE_URL + ilstr}
              className="img-fluid rounded"
              onError={(e) => (e.target.style.display = "none")}
            /> */}
            <iframe
              src={REDIRECT_BASE_URL + endUrl}
              width="100%"
              height="500"
              style={{ pointerEvents: "none" }}
              onError={(e) => e.target.src = IMG_BASE_URL + ilstr}

            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DisplayAnswers;
